// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_modal__30MRu {}\n\n@media (min-width: 640px) {\n    .Modal_modal__30MRu {\n        left: calc(50% - 250px)\n    }\n}", "",{"version":3,"sources":["webpack://src/components/UI/Modal/Modal.module.css"],"names":[],"mappings":"AAAA,qBAAQ;;AAER;IACI;QACI;IACJ;AACJ","sourcesContent":[".modal {}\n\n@media (min-width: 640px) {\n    .modal {\n        left: calc(50% - 250px)\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "Modal_modal__30MRu"
};
export default ___CSS_LOADER_EXPORT___;
